var config = require('../config');
var utils = require('./utils');
var WebEmbed = require('@tickaroo/web-embed');
i18n = require('../i18n/i18n');

function checkLocalStorageAvailable() {
  var isLocalStorageAvailable = false
  try {
    if(window.localStorage) {
      isLocalStorageAvailable = true
    }
  }
  catch (error) {}
  return isLocalStorageAvailable;
}

module.exports = function($container, callFeatures, options, classPrefix) {
  this.$container = $container;
  this.callFeatures = callFeatures;
  this.classPrefix = classPrefix;
  this.options = options;
  
  this.executableProviderOptions = this.options.executableProviderOptions;
  this.webEmbedConsentConstraints = utils.extend({}, options.webEmbedConsentConstraints, {"Website": true});
  
  var customEmbedConsentGiven = { "Facebook": false, "Twitter": false, "Instagram": false, "Polldozer": false, "Gfycat": false, "Opta": false };
  var customEmbedConsentGivenKey = "tickaroo-embedconsent";
  
  var isLocalStorageAvailable = checkLocalStorageAvailable();
  

  if(isLocalStorageAvailable) {
    var entry = window.localStorage.getItem(customEmbedConsentGivenKey);
    if(entry) {
      customEmbedConsentGiven = JSON.parse(entry);
    }
  }
  
  this.defaultWebEmbedConsentCallback = function(platform) {
    return customEmbedConsentGiven[platform];
  };
  
  this.defaultWebEmbedConsentUpdateCallback = function(platform, consent) {
    customEmbedConsentGiven[platform] = consent;
    if(isLocalStorageAvailable) {
      window.localStorage.setItem(customEmbedConsentGivenKey, JSON.stringify(customEmbedConsentGiven));
    }
  };

  if(this.options.showWebEmbeds == 'consent_handling')
  {
    this.webEmbedConsentCallback = this.options.webEmbedConsentCallback || this.defaultWebEmbedConsentCallback;
    this.webEmbedConsentUpdateCallback = this.options.webEmbedConsentUpdateCallback || this.defaultWebEmbedConsentUpdateCallback;
  } else if(this.options.showWebEmbeds === 'custom_consent_handling') {
    this.webEmbedConsentCallback = this.options.webEmbedConsentCallback || function() { return false; };
    this.webEmbedConsentUpdateCallback = this.options.webEmbedConsentUpdateCallback 
  } else {
    this.webEmbedConsentCallback = null;
    this.webEmbedConsentUpdateCallback = null;
  }

  this.mediaServerUrl = config.webEmbedEndpointUrl.replace("CLIENTID", options.clientId);

  this.webEmbed = new WebEmbed({
    mediaServerUrl: this.mediaServerUrl,
    locale: utils.detectAndSetLocale(options.locale),
    corsRequestFn: utils.corsRequestFn
  });
};

module.exports.prototype.updateLocale = function(locale) {
  this.webEmbed = new WebEmbed({
    mediaServerUrl: this.mediaServerUrl,
    locale: utils.detectAndSetLocale(locale),
    corsRequestFn: utils.corsRequestFn
  });
};

module.exports.prototype.updateHandledContent = function() {
  var translations = {
    external_consent_show: i18n.t('embed.consent.external_consent_show'),
    external_consent_hide: i18n.t('embed.consent.external_consent_hide'),
    external_consent_hint: i18n.t('embed.consent.external_consent_hint')
  };
  this.webEmbed.loadAll({
    container: this.$container,
    selector: this.classPrefix('event-item-content-web-embed-item', true, true),
    onCompleteAll: function(handledTimes) {
      if(handledTimes > 0) {
        this.callFeatures('updateHandledContent');
      }
    }.bind(this),
    webEmbedConsentConstraints: this.webEmbedConsentConstraints,
    webEmbedConsentCallback: this.webEmbedConsentCallback,
    webEmbedConsentUpdateCallback: this.webEmbedConsentUpdateCallback,
    callFeatures: this.callFeatures,
    translations: translations,
    executableProviderOptions: this.executableProviderOptions
  });
};

module.exports.prototype.destroy = function() {
};
