define("config", ['query-string'], function(queryString) {

  var q = {};
  try {
    q = queryString.parse(location.search);
  } catch(e) {
    console.error(e);
  }

  function featureFlag(config, env, name) {
    var flag = env[name];
    var queryFlag = q["tik_" + name];
    if(queryFlag === "true") {
      flag = true;
    } else if(queryFlag === "false") {
      flag = false;
    }
    config[name] = flag;
  }

  var config = {

    update: function(env) {

      this.tickarooBaseUrl = env.tickarooBaseUrl || "https://cdn.tickaroo.com";
      this.embedApiBaseUrl = env.embedApiBaseUrl || "//cdn.tickaroo.com";
      this.analyticsApiBaseUrl = env.analyticsApiBaseUrl || "//cdn.tickaroo.com";
      this.refreshInterval = env.refreshInterval || 20000;
      this.clientId = env.clientId || "CLIENTID";
      this.dateFormat = {
        nowThreshold: 45 * 1000,
        relativeThreshold: 60 * 60 * 1000
      };
      // featureFlag(this, env, "enableComments");

      var useV2 = env.useV2 || 0;
      if(q.tickarooUseV2 === 'true') {
        useV2 = 1;
      } else if(q.tickarooUseV2 === 'false') {
        useV2 = 0;
      }

     if(useV2 && (useV2 === 1 || Math.random() < useV2) ) {
        this.embedTickerUrlPattern = env.embedTickerUrlPattern || (this.embedApiBaseUrl + "/embed/v2/ticker.json?id=TICKERID&" +
          "client_id=CLIENTID&" +
          "locale=LOCALE&" +
          "show_scoreboard=SHOWSCOREBOARD&" +
          "show_event_meta=SHOWEVENTMETA&" +
          "limit=LIMIT&" +
          "load_more_limit=LOADMORELIMIT&" +
          "deep_link_limit=DEEPLINKLIMIT&" +
          "style=STYLE&" +
          "show_event_sharing=EVENTSHARING&" +
          "show_highlights=SHOWHIGHLIGHTS&" +
          "show_team_icons=SHOWTEAMICONS&" +
          "show_lineup=SHOWLINEUP&" +
          "show_navigation=SHOWNAVIGATION&" +
          "tag_filter=TAGFILTER&" +
          "reverse=REVERSE&" +
          "blacklisted_scoreboard_affiliations=BLACKLISTED_SCOREBOARD_AFFILIATIONS&" +
          "whitelisted_scoreboard_affiliations=WHITELISTED_SCOREBOARD_AFFILIATIONS&" +
          "image_width=IMAGE_WIDTH");
      } else {
        this.embedTickerUrlPattern = env.embedTickerUrlPattern || (this.embedApiBaseUrl + "/embed/ticker/TICKERID.json?" +
          "client_id=CLIENTID&" +
          "locale=LOCALE&" +
          "show_scoreboard=SHOWSCOREBOARD&" +
          "show_event_meta=SHOWEVENTMETA&" +
          "limit=LIMIT&" +
          "load_more_limit=LOADMORELIMIT&" +
          "style=STYLE&" +
          "show_event_sharing=EVENTSHARING&" +
          "show_highlights=SHOWHIGHLIGHTS&" +
          "show_team_icons=SHOWTEAMICONS&" +
          "show_lineup=SHOWLINEUP&" +
          "show_navigation=SHOWNAVIGATION&" +
          "tag_filter=TAGFILTER&" +
          "reverse=REVERSE&" +
          "blacklisted_scoreboard_affiliations=BLACKLISTED_SCOREBOARD_AFFILIATIONS&" +
          "whitelisted_scoreboard_affiliations=WHITELISTED_SCOREBOARD_AFFILIATIONS&" +
          "image_width=IMAGE_WIDTH");
      }

      this.embedSharingPost = this.tickarooBaseUrl + "/api/v5/sharing/post.json?client_id=CLIENTID";
      this.embedShareEventRedirectUrl = this.tickarooBaseUrl + "/share/event/EVENTID?client_id=CLIENTID&target=TARGET";
      this.webEmbedEndpointUrl = env.webEmbedEndpointUrl || (this.embedApiBaseUrl + "/api/mediaservice/v3/web_embed.json?client_id=CLIENTID");

      this.embedGamedayScore = this.embedApiBaseUrl + "/embed/gameday_score.json";
    }
  };

  return config;
});
