
function sessionStorageAvailable(){
  var test = 'test';
  try {
    if(!window.sessionStorage){
      return false;
    }
    window.sessionStorage.setItem(test, test);
    window.sessionStorage.removeItem(test);
    return true;
  } catch(e) {
      return false;
  }
}

var sessionStorage;

if(sessionStorageAvailable()){
  sessionStorage = window.sessionStorage;
} else {
  sessionStorage = {

    _keys: [],
  
    setItem: function(key, value) {
      this._keys[key] = value;
    },
  
    getItem: function(key) {
      return this._keys[key];
    },
  
    removeItem: function(key) {
      delete this._keys[key];
    },
  
    clear: function() {
      this._keys = {};
    }
  
  };
}

module.exports = {

  _makeTickerKey: function(ticker) {
    return "tik-embed-user-" + ticker;
  },

  updateUserSetting: function(ticker, settings) {
    var tickerKey = this._makeTickerKey(ticker);
    sessionStorage.setItem(tickerKey, JSON.stringify(settings || {}))
  },

  getUserSettings: function(ticker) {
    var tickerKey = this._makeTickerKey(ticker);
    var value = sessionStorage.getItem(tickerKey) || {};
    try {
      return value ? JSON.parse(value) : {};
    } catch(e) {
      return {};
    }
  }

};
