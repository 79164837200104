define("tik3", [
                './tik3_embed',
                './config',
                './embed/utils',
                './config/development',
                './config/production',
                './config/staging',
                './config/testing',
            ], function(embed, config, utils, developmentEnv, productionEnv, stagingEnv, testingEnv) {
  return function() {
    window.Tickaroo = window.Tickaroo || {};
    utils.extend(window.Tickaroo, embed);

    function findClientStyleTag() {
      var tags = document.getElementsByTagName("link");
      for(var i = 0; i < tags.length; ++i) {
        var tag = tags[i];
        if(tag.href) {
          var match = tag.href.match(/\/tik3\.css/);
          if(match) {
            return tag.href;
          }
        }
      }
      return null;
    }

    function createClientStyleTag() {
      var style = document.createElement('link');
      style.href = config.embedApiBaseUrl + '/embedjs/tik3.css';
      style.type = "text/css";
      style.rel = "stylesheet";
      document.head.appendChild(style);
    }

    function findClientScriptTag() {
      var scriptTags = document.getElementsByTagName("script");
      for(var i = 0; i < scriptTags.length; ++i) {
        var scriptTag = scriptTags[i];
        if(scriptTag.src) {
          var match = scriptTag.src.match(/\/tik3\.js/);
          if(match) {
            return scriptTag.src;
          }
        }
      }
      return null;
    }

    function findParamsInScriptTagScr(scriptTagScr, findRegex) {
      if (scriptTagScr) {
        var match = scriptTagScr.match(findRegex);
        if(match) {
          return match[1];
        }
      }
    }

    utils.domready(function () {

      var envToUse = productionEnv;
      var scriptTagScr = findClientScriptTag();

      switch(findParamsInScriptTagScr(scriptTagScr, /env=([a-z0-9]+)/)) {
      case "production":
        envToUse = productionEnv;
        break;
      case "staging":
        envToUse = stagingEnv;
        break;
      case "testing":
        envToUse = testingEnv;
        break;
      case "development":
        envToUse = developmentEnv;
        break;
      }

      envToUse.clientId = findParamsInScriptTagScr(scriptTagScr, /client_id=([a-z0-9]+)/);

      config.update(envToUse);

      if(!findClientStyleTag()) {
        createClientStyleTag();
      }

      // get the existing _tik array
      var _old_tik = null;
      if(window._tik) {
         _old_tik = window._tik.q;
       }

      // create a new _tik object
      window._tik = embed.q;

      // execute all of the queued up events - apply() turns the array entries into individual arguments
      if(_old_tik) {
        for (var i = 0; i < _old_tik.length; i++) {
          window._tik.apply(window._tik, _old_tik[i]);
        }
      }
    });
  };
});

(new function() {
  "use strict";

  var tik3 = require('tik3');
  tik3();

}());
