var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var currentUser = undefined;
function validateCurrentUserObject(currentUser) {
    if (typeof currentUser.name !== 'string' || currentUser.name.length == 0) {
        throw new Error("currentUser.name must be a string");
    }
    if (typeof currentUser.idTokenCallback !== 'function') {
        throw new Error("currentUser.idTokenCallback must be a function");
    }
    return true;
}
export function setCurrentUser(inCurrentUser) {
    if (inCurrentUser) {
        if (validateCurrentUserObject(inCurrentUser)) {
            currentUser = inCurrentUser;
            triggerCurrentUserListener(currentUser);
        }
    }
    else {
        currentUser = undefined;
        triggerCurrentUserListener(currentUser);
    }
}
export function getCurrentUser() {
    return currentUser;
}
var listeners = [];
export function addCurrentUserListener(listener) {
    listeners = __spreadArray(__spreadArray([], listeners), [listener]);
}
export function removeCurrentUserListener(listener) {
    listeners = listeners.filter(function (l) { return l !== listener; });
}
function triggerCurrentUserListener(currentUser) {
    listeners.forEach(function (value) { return value(currentUser); });
}
export var triggerLogin = undefined;
export var triggerLogout = undefined;
export function setTriggerLoginFunction(f) {
    triggerLogin = f;
}
