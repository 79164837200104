var utils = require('./utils');

function createModule(js) {
	try {
		var moduleConstructor = new Function('module',  js);
		var newModule = { exports: {}};
		moduleConstructor(newModule);
		return newModule;
	} catch (e) {
		console.error(e);
	}
}

module.exports = function ($container, js, options) {
  this.$container = $container;
	this.options = options;
	var module = createModule(js);
	utils.extend(this, module.exports);

	this.init();
};

module.exports.prototype.init = function () {

};

module.exports.prototype.tracking = function () {

};

module.exports.prototype.updateHandledContent = function() {

};

module.exports.prototype.destroy = function() {

};

module.exports.prototype.itemDOMPostProcessor = function(domItem) {

};


