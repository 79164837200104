var config = require('../config');
var utils = require('./utils');

require('../css/video.scss');

var Video = function Video(options) {
  if(!options.id) {
    throw("Required parameter id missing");
  }
  if (!options.clientId) {
    options.clientId = config.clientId;
  }
  this.options = options;
  this.$container = utils.findOrCreateContainer(options.container);

  this.init();
};

Video.prototype.init = function init() {
  var src = config.embedApiBaseUrl + "/embed/video-frame/" + this.options.id + ".html?client_id=" + this.options.clientId;

  var iframe = document.createElement("iframe");
  iframe.classList.add("tickaroo-video-player-frame");
  iframe.setAttribute("frameborder", "0");
  iframe.setAttribute("src", src);
  iframe.setAttribute("allowfullscreen", true);
  iframe.setAttribute("referrerpolicy", "unsafe-url");

  var iframeContainer = document.createElement("div");
  iframeContainer.classList.add("tickaroo-video-player");
  iframeContainer.appendChild(iframe);

  this.$container.appendChild(iframeContainer);

  this.$iframeContainer = iframeContainer;
  this.$iframe = iframe;
};

Video.prototype.destroy = function destroy() {
  this.$iframeContainer = null;
  this.$iframe = null;
  this.$iframeContainer.remove();
};

function embedVideo(options) {
   return new Video(options);
}

module.exports = {
  embedVideo: embedVideo
};
