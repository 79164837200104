define('embed/iframe', ['./utils', '../config'],
  function(utils, config) {

    var iframes = {};
    var embedIframeUrlDefaultOptions = {
      autosize: true,
      width: '1px',
      minWidth: '100%',
      height: '320px'
    };

    var listener = function(event) {
      if(event.origin && typeof event.data === "string" && event.data.charAt(0) === '{') {
        var msg = JSON.parse(event.data);
        if(msg.tikEmbedIframeName && msg.tikEmbedIframeHeight && iframes[msg.tikEmbedIframeName]) {
          options = iframes[msg.tikEmbedIframeName];
          if(options.autosize) {
            options.frame.setAttribute("style", "width: " + options.width + "; min-width: " + options.minWidth + "; height: " + msg.tikEmbedIframeHeight + "px");
          }
        }
      }
    };


    var embedIframeUrl = function(inOptions) {
      var uid = utils.generateUID();
      var options = utils.extend({}, embedIframeUrlDefaultOptions, inOptions);

      var $container = utils.findOrCreateContainer(options.container);
      if(!options.url) { utils.raise("Missing URL"); }

      var url = options.url;

      // this is hack for russmedia wordpress.
      url = url.replace('&#038;;', '&').replace('&amp;', '&');

      if(options.skipInitialTracking){
        url = utils.addParameterToURL(url, "skip_initial_tracking", "true")
      }

      var frame = document.createElement("iframe");
      frame.setAttribute("name", uid);
      frame.setAttribute("allowfullscreen", true);
      frame.setAttribute("frameborder", 0);
      frame.setAttribute("scrolling", "no");
      frame.setAttribute("src", url);
      frame.setAttribute("style", "width: " + options.width + "; min-width: " + options.minWidth + "; height: " + options.height + "");
      if(options.sandbox){
        frame.setAttribute("sandbox", options.sandbox);
      }

      utils.addEventListener(window, "message", listener);

      options.frame = frame
      iframes[uid] = options;
      $container.appendChild(frame);

      return {
        dispose: function() {
          delete iframes[uid];
          frame.remove();
        }
      };
    };


    return {
      embedIframeUrl: embedIframeUrl
    };
  }
);
