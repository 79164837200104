define("env", [], function() {
  return {
    tickarooBaseUrl: "http://127.0.0.1:4444",
    embedApiBaseUrl: "http://127.0.0.1:4444",
    analyticsApiBaseUrl: "http://127.0.0.1:4444",
    refreshInterval: 5000,
    webEmbedEndpointUrl: "//www.tickaroo.com/api/mediaservice/v3/web_embed.json?client_id=CLIENTID",
    embedTickerUrlPattern: "//127.0.0.1:4444/embed/v2/ticker.json?id=TICKERID&" +
      "client_id=CLIENTID&" +
      "locale=LOCALE&" +
      "show_scoreboard=SHOWSCOREBOARD&" +
      "show_event_meta=SHOWEVENTMETA&" +
      "limit=LIMIT&" +
      "load_more_limit=LOADMORELIMIT&" +
      "style=STYLE&" +
      "show_event_sharing=EVENTSHARING&" +
      "show_highlights=SHOWHIGHLIGHTS&" +
      "show_team_icons=SHOWTEAMICONS&" +
      "show_lineup=SHOWLINEUP&" +
      "show_navigation=SHOWNAVIGATION&" +
      "tag_filter=TAGFILTER&" +
      "reverse=REVERSE&" +
      "blacklisted_scoreboard_affiliations=BLACKLISTED_SCOREBOARD_AFFILIATIONS&" +
      "whitelisted_scoreboard_affiliations=WHITELISTED_SCOREBOARD_AFFILIATIONS&" +
      "image_width=IMAGE_WIDTH"

  };
});
