
i18n = require('../i18n/i18n');

define('embed/ticker_setup', ['./utils', './user_display_settings', './feature_tag_filter', './feature_timestamps', './feature_event_sharing', './feature_web_embeds', './feature_media', './feature_responsive', './feature_seo', './feature_custom_js', './feature_event_id', './feature_auto_refresh', './feature_comments', '../config', 'tickaroo-analytics-agent', '@tickaroo/ad-sdk'],
  function(utils, userDisplaySettings, featureTagFilter, featureTimestamps, featureEventSharing, featureWebEmbed, featureMedia, featureResponsive, featureSeo, featureCustomJs, featureEventId, featureAutoRefresh, featureComments, config, AnalyticsAgent, AdSDK) {

    return function(options, defaults, selectors, isTik3) {
      options = utils.extend({
        style: 'default',
        limit: 15,
        deepLinkLimit: 0,
        deepLinkDepth: 0,
        enableSeo: true,
        enableCustomJs: true,
        locale: undefined,
        showHighlights: true,
        showTeamIcons: false,
        reverseEvents: false,
        handleScoreboard: 'auto',
        handleMedia: 'auto',
        showWebEmbeds: 'show',
        executableProviderOptions: [],
        handleTimestamps: true,
        handleRefresh: 'auto',
        coalesceRepeatedMeta: defaults.coalesceRepeatedMeta,
        refreshInterval: config.refreshInterval,
        showLineup: false,
        showEventMeta: defaults.showEventMeta,
        showEventSharing: false,
        showNavigation: 'off',
        tagFilter: '',
        itemDOMPostProcessor: undefined,
        referrer: window.location.href,
        imageWidth: 480
      }, options);

      if (!options.loadMoreLimit) {
        options.loadMoreLimit = options.limit;
      }

      if (!options.clientId) {
        options.clientId = config.clientId;
      }

      if (!options.showEventMeta) {
        options.showEventMeta = "off";
      } else if (options.showEventMeta === true) {
        options.showEventMeta = 'reporter';
      }

      if (!options.handleMedia) {
        options.handleMedia = "off";
      } else if (options.style === 'rponline') { // hack for rp-online settings
        options.handleMedia = 'inline';
      } else if (options.handleMedia === true) {
        options.handleMedia = "auto";
      }

      if (!options.handleRefresh) {
        options.handleRefresh = "off";
      } else if (options.handleRefresh === true) {
        options.handleRefresh = "auto";
      }

      if(options.imageWidth) {
        options.imageWidth = Math.ceil(options.imageWidth / 32) * 32
      }
      

      var uid = utils.generateUID();
      // the container to work with
      var $container = utils.findOrCreateContainer(options.container);
      // id of the ticker to work with
      var id = options.id;
      // set to true if destroy is initialized
      var state = 'init';
      // if refresh is configured to only notify, this buffers the latest update result so
      // we can use it to quickly update on request
      var pendingResult = null;
      // dom processor stack
      var itemDOMPostProcessors = options.itemDOMPostProcessor ? [options.itemDOMPostProcessor] : [];

      var analytics;
      var retentionTracker;
      if (!options.skipTickarooTracking) {
        analytics = new AnalyticsAgent({
          clientId: options.clientId,
          baseUrl: config.analyticsApiBaseUrl,
          ua_location: options.referrer,
          ua_referrer: "null",
          corsRequestFn: utils.corsRequestFn
        });
        if (id) {
          analytics.track({
            _type: "Tik::ApiModel::Analytics::EmbedJSLiveblog",
            js_version: options.version,
            ticker_id: options.id,
            locale: options.locale || 'auto',
            opt_event_id: options.eventId || null,
            opt_event_meta: options.showEventMeta,
            opt_reverse: options.reverseEvents,
            opt_sharing: options.showEventSharing,
            opt_tracking: !!options.trackingCallback,
            opt_custom_ads: !!options.customAdCallback
          });
        }
      }
      // Caution: this function is copy-pasted in gameday_scores_setup.js as well
      var classPrefix = function(className, hasDot, hasJS){
        var p = hasJS ? selectors.prefixJS : selectors.prefix;
        if (hasDot) {
          return "." + p + "-" + className;
        } else {
          return p + "-" + className;
        }
      };

      var ad = new AdSDK({
        corsRequestFn: utils.corsRequestFn,
        analyticsAgent: analytics,
        customerFrameworkAdFn: function(callback) {
          if(options.customAdCallback) {
            options.customAdCallback(id, callback);
          } else {
            callback();
          }
        }
      });

      // the object returned to the user;
      var returnObject = {
        uid: uid
      };

      var errorHandler = function errorHandler(category, message) {
        if(typeof message === 'object' && message.hasOwnProperty("description")) {
          message = message.description;
        }

        if(analytics) {
          analytics.track({
            _type: "Tik::ApiModel::Analytics::EmbedJSError",
            js_version: options.version,
            ticker_id: options.id,
            category: category,
            message: message
          });
        }

        console.error(message)
      };

      var errorWrapper = function errorWrapper(fn) {
        return function() {
          try {
            return fn.apply(this, arguments);
          } catch(ex) {
            errorHandler("ex", ex);
          }
        };
      };

      var trackingCallback = function(id, reason, type, foundContent) {
        if (options.trackingCallback) {
          try {
            options.trackingCallback.apply(returnObject, [id, reason, type, foundContent]);
          } catch (e) {
            console.error('Tickaroo EmbedJS: Error in the trackingCallback custom function!');
            console.error(e);
          }
        }
        callFeatures("tracking", { id: id, reason: reason, type: type, foundContent: foundContent });
      };

      var coalesceRepeatedMeta = function ($container) {
        var i, metaNodes, currentMetaNode, nextMetaNode, hideCls;
        hideCls = ' ' + classPrefix('ticker-event-item-meta-reporter-hidden', false);
        metaNodes = $container.querySelectorAll(selectors.eventReporter);
        for (i = 0; i < metaNodes.length; i++) {
          currentMetaNode = metaNodes[i];
          nextMetaNode = metaNodes[i + 1];
          if (currentMetaNode && nextMetaNode) {
            if (currentMetaNode.getAttribute('data-reporter-id') && (currentMetaNode.getAttribute('data-reporter-id') === nextMetaNode.getAttribute('data-reporter-id'))) {
              if (nextMetaNode.className.indexOf(hideCls) === -1) {
                nextMetaNode.className += hideCls;
              }
            } else {
              nextMetaNode.className = nextMetaNode.className.split(hideCls).join(' ');
            }
          }
        }
      };

      var insertTickerItems = function(result) {
        var eventListEl = $container.querySelector(selectors.eventList);
        var items = result.insert;
        if (result.reset === true) {
          utils.emptyElement(eventListEl);
        }
        var currentChild = eventListEl.firstChild;
        for (var i = 0; i < items.length; ++i) {
          // make a dom item
          var item = items[i];
          var itemNode = utils.domify(item.html);
          // apply postprocessing
          for (var pi = 0; pi < itemDOMPostProcessors.length; ++pi) {
            try {
              itemDOMPostProcessors[pi].apply(returnObject, [itemNode]);
            } catch (e) {
              console.error('Tickaroo EmbedJS: Error in the itemDOMPostProcessor custom function!');
              console.error(e);
            }
          }

          callFeatures("itemDOMPostProcessor", itemNode);

          // do the insert
          var handled = false;
          while (!handled) {
            if (!currentChild) {
              if (!item.deleted) {
                eventListEl.appendChild(itemNode);
                callFeatures("afterEventNodeMount", itemNode);
              }
              handled = true;
            } else if (currentChild.nodeName != 'LI') {
              currentChild = currentChild.nextElementSibling;
            } else if (currentChild.getAttribute('data-tickaroo-id') == item.id) {
              var oldChild = currentChild;
              currentChild = currentChild.nextElementSibling;

              // remove child and probably reinsert it at its sort value
              callFeatures("beforeEventNodeUnmount", itemNode);
              eventListEl.removeChild(oldChild);

              // if it has deleted flag, we are done with it here
              if (item.deleted) {
                handled = true;
              }
            } else if (currentChild.getAttribute('data-tickaroo-sort') < item.sort) {
              if (!item.deleted) {
                eventListEl.insertBefore(itemNode, currentChild);
                callFeatures("afterEventNodeMount", itemNode);
              }

              // search if the element already exists below the current element and remove it
              var previewChild = currentChild;
              while (previewChild) {
                if (previewChild.getAttribute('data-tickaroo-id') == item.id) {
                  callFeatures("beforeEventNodeUnmount", itemNode);
                  eventListEl.removeChild(previewChild);
                  break;
                }
                previewChild = previewChild.nextElementSibling;
              }
              handled = true;
            } else {
              currentChild = currentChild.nextElementSibling;
            }
          }
        }
      };

      var scrollToEvent = function (eventId, failureActions, actionItem) {
        var failureAction, eventEl;
        eventEl = document.querySelector("[data-tickaroo-id='" + eventId + "']");
        if (eventEl) {
          utils.scrollToEl(eventEl);
        } else if (failureActions) {
          var i;
          //todo: test multible actions!
          for (i = 0; i < failureActions.length; i++) {
            failureAction = failureActions[i];
            if (failureAction._type === "Tik::ApiModel::GetAction" && failureAction.url) {
              loadTicker(failureAction.url, 'user', 'anchor', function() {
                setTimeout(function(){ scrollToEvent(eventId, null, null); }, 1000);
              });
            }
          }
        } else {
          console.warn("Tickaroo EmbedJS: couldn't scroll to event");
        }
      };

      var updateTagFilter = function(tags) {
        options.tagFilter = tags;
        userDisplaySettings.updateUserSetting(options.id, {
          tag_filter: options.tag_filter
        });
        var tagFilterUrl = utils.addParameterToURL(urlPatternParser(options.urlPattern), "deep_link", "true");
        loadTicker(tagFilterUrl, 'user', 'filter');
      };

      var urlPatternParser = function (url) {
        return url
        .replace('TICKERID', options.id || options.outputChannelId)
        .replace('LOCALE', utils.detectAndSetLocale(options.locale))
        .replace('CLIENTID', options.clientId)
        .replace('REVERSE', options.reverseEvents)
        .replace('SHOWHIGHLIGHTS', options.showHighlights)
        .replace('SHOWTEAMICONS', options.showTeamIcons)
        .replace('SHOWLINEUP', options.showLineup)
        .replace('SHOWSCOREBOARD', options.handleScoreboard)
        .replace('SHOWEVENTMETA', options.showEventMeta)
        .replace('SHOWNAVIGATION', options.showNavigation)
        .replace('LIMIT', options.limit)
        .replace('DEEPLINKLIMIT', options.deepLinkLimit)
        .replace('LOADMORELIMIT', options.loadMoreLimit)
        .replace('STYLE', options.style)
        .replace('http:', window.location.protocol)
        .replace('https:', window.location.protocol)
        .replace('EVENTSHARING', options.showEventSharing)
        .replace('TAGFILTER', encodeURIComponent(options.tagFilter || ''))
        .replace('BLACKLISTED_SCOREBOARD_AFFILIATIONS', encodeURIComponent(options.blacklistedScoreboardAffiliations || ''))
        .replace('WHITELISTED_SCOREBOARD_AFFILIATIONS', encodeURIComponent(options.whitelistedScoreboardAffiliations || ''))
        .replace('IMAGE_WIDTH', encodeURIComponent(options.imageWidth || ''));
      };

      var initialLoadUrl = urlPatternParser(options.urlPattern);
      var navigateToEventId = function(eventId, options) {
        options = options || {};

        var existingEl = $container.querySelector("[data-tickaroo-id=\"" + eventId + "\"]");
        if(existingEl) {
          utils.scrollToEl(existingEl, {topOffset: options.topOffset});

          if(options.callback) options.callback();
        } else {
          var loadEventUrl = utils.addParameterToURL(initialLoadUrl, "event_local_id", eventId);

          var moreLinks = $container.querySelectorAll("[data-tickaroo-action-item-id=events-more-item]");
          if(moreLinks.length === 1) {
            var moreLink = moreLinks[0];
            var refJSON = moreLink.getAttribute("data-tickaroo-action-item-ref");
            if (refJSON) {
              try {
                var ref = JSON.parse(refJSON.replace(/\\u0022/g, '"').replace(/\\u0026/g, "&"));
                loadEventUrl = utils.addParameterToURL(loadEventUrl, "more",  ref.more);
              } catch (e) { }
            }
          }

          loadTicker(loadEventUrl, 'user', 'anchor', function() {
            setTimeout(function() {
              var existingEl = $container.querySelector("[data-tickaroo-id=\"" + eventId + "\"]");
              utils.scrollToEl(existingEl, {topOffset: options.topOffset});
              if(options.callback) options.callback();
            }, 1000);
          });
        }
      };

      var actionItemClickHandler = function (e) {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
        var actionItem, actionItemRefString, target;
        target = e.target || e.srcElement;
        actionItem = target.closest(selectors.actionItem);
        if (actionItem) {
          actionItemRefString = actionItem.getAttribute('data-tickaroo-action-item-ref');
          if (actionItemRefString) {
            actionItemRefString = actionItemRefString.replace(/\\u0022/g, '"').replace(/\\u0026/g, "&");
            actionItemRefObject = JSON.parse(actionItemRefString);
            if (actionItemRefObject._type === "Tik::ApiModel::ActionRef" && actionItemRefObject.action) {
              switch (actionItem.getAttribute('data-tickaroo-action-item-type')) {
                case 'button':
                  if (actionItemRefObject.action && actionItemRefObject.action.url) {
                    loadTicker(actionItemRefObject.action.url, 'more');
                  }
                  break;
                case 'milestone_link':
                  scrollToEvent(actionItemRefObject.action.anchor, actionItemRefObject.action.failure_actions, actionItem);
                  break;
                default:
                  console.error("Tickaroo EmbedJS: Unknown action type");
              }
            } else {
              switch (actionItem.getAttribute('data-tickaroo-action-item-type')) {
                case 'more':
                  var moreUrl = utils.addParameterToURL(initialLoadUrl, "more", actionItemRefObject.more);
                  loadTicker(moreUrl, 'user', 'more');
                  break;
                case 'prev':
                  var prevUrl = utils.addParameterToURL(initialLoadUrl, "prev", actionItemRefObject.more);
                  loadTicker(prevUrl, 'user', 'more');
                  break;
                case 'live':
                  var liveUrl = utils.addParameterToURL(initialLoadUrl, "deep_link", "true");
                  loadTicker(liveUrl, 'user', 'more');
                  break;
                case 'set_tag_filter':
                  updateTagFilter(actionItemRefObject.tag);
                  break;
                case 'remove_tag_filter':
                  var tags = options.tagFilter.split(",");
                  var index = tags.indexOf(actionItemRefObject.tag);
                  if(index >=0) {
                    tags.splice(index, 1);
                    updateTagFilter(tags.join(','));
                  }
                  break;
                case 'milestone_link':
                  var eventId = actionItemRefObject.event_id;
                  navigateToEventId(eventId);
                  break;
              }
            }
          }
        }
      };



      utils.delegate($container, 'click', selectors.actionItem.replace('.', ""), errorWrapper(actionItemClickHandler));

      utils.delegate($container, 'change', selectors.actionItem.replace('.', ""), errorWrapper(function(e) {
        var target = e.target;
        var value = target.value;
        if(value) {
          switch (target.getAttribute('data-tickaroo-action-item-type')) {
            case 'set_tag_filter':
              updateTagFilter(value);
              break;
            case 'add_tag_filter':
              var tags = options.tagFilter ? options.tagFilter.split(",") : [];
              var index = tags.indexOf(value);
              if (index < 0) {
                tags.push(value);
                updateTagFilter(tags.join(','));
              }
              break;
          }
        }
      }));


      var loadUserRef = function(refJson) {
        if(refJson) {
          var ref = JSON.parse(refJson);

          userDisplaySettings.updateUserSetting(options.id, {
            tag_filter: ref.tag_filter
          });

          loadTicker(refToProUrl(ref), 'user', 'filter');
        }
      };



      utils.delegate($container, 'click', selectors.refItem.replace('.', ""), errorWrapper(function(e) {
        e.preventDefault();
        var target = e.realTarget;
        var refJson = target.getAttribute("data-tickaroo-ref");
        loadUserRef(refJson);
      }));

      utils.delegate($container, 'change', selectors.refItem.replace('.', ""), errorWrapper(function(e) {
        var target = e.target;
        var refJson = target.value;
        loadUserRef(refJson);
      }));


      var updateWithResult = function(result) {
        if(result.config) {
          if(result.config.locale) {
            if(result.config.locale != options.locale) {
              callFeatures('updateLocale', result.config.locale);
              utils.detectAndSetLocale(result.config.locale)
            }
            options.locale = result.config.locale
          }
        }
        if (result.content) {
          $container.innerHTML = result.content;
        }
        if (result.scoreboard && options.handleScoreboard != 'off') {
          var scoreboardContaiernElem = $container.querySelector(selectors.scoreboardContainer);
          if (scoreboardContaiernElem) {
            scoreboardContaiernElem.innerHTML = result.scoreboard;
          } else {
            console.warn("Tickaroo: Element scoreboard-container not found");
          }
        }
        if (result.insert && result.insert.length > 0) {
          insertTickerItems(result);
          if ((options.showEventMeta === 'all' || options.showEventMeta === 'reporter') && options.coalesceRepeatedMeta) {
            coalesceRepeatedMeta($container);
          }
        }
        if(result.refreshLink){
          config.refreshLink = result.refreshLink;
        }
        if(result.version) {

        }

        if (result.count > 0 || (result.scoreboard && options.handleScoreboard != 'off')) {
          callFeatures('updateHandledContent');
        }


        ad.fillAllAds();
      };

      var updateWithPendingResult = function() {
        var count = 0;
        if (pendingResult) {
          count = pendingResult.count;
          updateWithResult(pendingResult);
          callFeatures("setVersion", {result: pendingResult});
          pendingResult = null;
        }
        trackingCallback(id, 'user', 'update-pending', count);
      };

      var refToProUrl = function(ref) {
        return config.embedApiBaseUrl + "/embed/ticker/" + options.id + ".json?" +
          utils.makeQueryString({
            locale: utils.detectAndSetLocale(options.locale),
            style: options.style,
            client_id: options.clientId,
            show_event_sharing: options.showEventSharing
          }) + '&' +
          utils.makeQueryString(ref);
      };

      var loadTicker = errorWrapper(function (url, reason, type, onSuccess) {
        if (url && options.style && url.indexOf('style=') == -1) {
          url = url + (url[url.length - 1] === '?' ? 'style=' : '&style=') + options.style;
        }
        if (state !== 'destroyed') {
          var request = utils.corsRequestFn();
          request.onSuccess = errorWrapper(function (result) {
            if (state !== 'destroyed') {
              if(!retentionTracker && analytics) {
                retentionTracker = analytics.createRetentionTracker({
                  _type: "Tik::ApiModel::TickerRef",
                  ticker_id: id
                });
              }


              features.push(new featureCustomJs($container, result.customJs, options, classPrefix));

              // always just update the content, EXCEPT when its a refresh and handleRefresh is set to "notify"
              if (type === 'refresh' && options.handleRefresh === 'notify') {
                pendingResult = result;
                returnObject.pendingItemsCount = pendingResult.count;
              } else {
                updateWithResult(result);
                if (options.eventId && reason === 'init' && type === 'init') {
                  setTimeout(function(){ scrollToEvent(options.eventId); }, 1000);
                }

                callFeatures("setVersion", {result: result});
              }

              callFeatures("processLoadTickerResult", {result: result, reason: reason, type: type});

              if (analytics && result.trackEvent) {
                result.trackEvent.client_id = options.clientId;
                result.trackEvent.loadreason = reason;
                analytics.track(result.trackEvent);
              }

              trackingCallback(id, reason, type, result.count);
            }
            if(onSuccess) {
              onSuccess();
            }
          });
          request.onFailure = function(request) {
            errorHandler("ld", "XHR Error: " + request.xhr.statusText);

            if(reason === 'init') {
              if(request.xhr.status === 404){
                xhrError(request.xhr.status, request.xhr.responseText, i18n.t('embed.load_errors.404_title'), i18n.t('embed.load_errors.404_description'));
              } else if(request.xhr.status === 403) {
                xhrError(request.xhr.status, request.xhr.responseText, i18n.t('embed.load_errors.403_title'), i18n.t('embed.load_errors.403_description'));
              } else if(request.xhr.status === 402) {
                xhrError(request.xhr.status, request.xhr.responseText, i18n.t('embed.load_errors.402_title'), i18n.t('embed.load_errors.402_description'));
              } else if(request.xhr.status === 401) {
                xhrError(request.xhr.status, request.xhr.responseText, i18n.t('embed.load_errors.401_title'), i18n.t('embed.load_errors.401_description'));
              } else {
                console.error("Tickaroo EmbedJS: Error loading the ticker:, ", request.xhr.status + " - " + request.xhr.responseText);
              }
            }
          };
          request.get(urlPatternParser(url));
        }
      });

      var xhrError = function(status, responseText, title, description) {

        if(!title){
          title = responseText
        }
        var html = '<div class="tik3-error"><div class="tik3-error-code">' + status + '</div><div class="tik3-error-content"><div class="tik3-error-headline">' + title + '</div><div class="tik3-error-description">' + description + '</div></div></div>';
        html += '<style>.tik3-error {display: flex;justify-content: center;flex-wrap: wrap;background: #ececec;padding: 10vw;}.tik3-error-code {font-size: 2em;font-weight: bold;color: #808080;margin-right: .5em;}.tik3-error-content {max-width: 360px;}.tik3-error-headline {font-weight: bold;margin-bottom: .3em;}</style>';
        $container.innerHTML = html;

        console.error("Tickaroo EmbedJS: Error loading the ticker: ", status + " - " + title + " " + description);
      };

      var features = [];

      var callFeatures = function(method, arg) {
        for(var i = 0; i < features.length; ++i) {
          var feature = features[i];
          if (feature[method]) {
            try {
              feature[method](arg);
            } catch (e) {
              errorHandler("ex", e);
            }
          }
        }
      };

      // Initialize Features
      features.push(new featureResponsive($container, classPrefix));
      features.push(new featureTagFilter($container, userDisplaySettings, options, classPrefix));
      features.push(new featureEventId($container, options, navigateToEventId));
      var featureAutoRefreshInst = new featureAutoRefresh(options, config, loadTicker, urlPatternParser, errorWrapper);
      features.push(featureAutoRefreshInst);
      features.push(new featureComments($container, options));

      if(options.handleTimestamps) {
        features.push(new featureTimestamps($container, null, classPrefix));
      }
      if (options.showEventSharing) {
        features.push(new featureEventSharing($container, urlPatternParser, options, classPrefix));
      }
      if(options.showWebEmbeds != 'off') {
        features.push(new featureWebEmbed($container, callFeatures, options, classPrefix));
      }
      if(options.handleMedia !== 'off') {
        features.push(new featureMedia($container, options.handleMedia, analytics, trackingCallback, urlPatternParser, uid, options, classPrefix, isTik3));
      }
      if(options.enableSeo) {
        features.push(new featureSeo(id, $container, options));
      }

      var loadObj = {
        url: urlPatternParser(options.urlPattern),
        reason: 'init',
        type: 'init',
        onSuccess: function() {
          callFeatures("postInitialLoad");
        },
        referrer: options.referrer
      };
      callFeatures("preInitialLoad", loadObj);
      loadTicker(loadObj.url, loadObj.reason, loadObj.type, loadObj.onSuccess);

      // Construct the public return object

      returnObject.refresh = errorWrapper(featureAutoRefreshInst.runRefresh.bind(featureAutoRefreshInst));
      returnObject.pendingItemsCount = 0;
      returnObject.updateWithPendingItems = errorWrapper(function() {
        updateWithPendingResult();
      });

      returnObject.navigateToEventId = navigateToEventId;

      returnObject.destroy = function() {
        if (state !== 'destroyed') {
          callFeatures('destroy');

          if(retentionTracker) {
            retentionTracker.destroy();
          }
          if (analytics) {
            analytics.destroy();
          }
          utils.undelegateAll($container);
          $container.innerHTML = '';
          state = 'destroyed';
          returnObject = undefined;
        }
      };

      return returnObject;
    };
  });
