
define('embed/ticker', ['./utils', './user_display_settings', './feature_timestamps', './feature_navigation_date.js', '../config', 'tickaroo-analytics-agent', '@tickaroo/ad-sdk'],
  function(utils, userDisplaySettings, featureTimestamps, featureNavigationDate, config, AnalyticsAgent, AdSDK) {

    utils.extend(this, utils);

    utils.installClosestPolyfill();


    var clientId;
    var tournamentId;
    var date;

    return function (options, selectors) {
      // Caution: this function is copy-pasted in ticker_setup.js as well
      var classPrefix = function(className, hasDot, hasJS){
        var p = hasJS ? selectors.prefixJS : selectors.prefix;
        if (hasDot) {
          return "." + p + "-" + className;
        } else {
          return p + "-" + className;
        }
      };

      if (!options) {
        utils.raise("Missing Options");
      }
      if (!options.clientId) {
        utils.raise("Missing Client ID");
      }
      clientId = options.clientId
      tournamentId = options.tournamentId
      date = options.date

      if(options.locale) {
        utils.detectAndSetLocale(options.locale)
      }

      var uid = utils.generateUID();
      // the container to work with
      var $container = utils.findOrCreateContainer(options.container);

      var actionItemClickHandler = function (e) {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
        var actionItem, actionItemRefString, target;
        target = e.target || e.srcElement;
        actionItem = target.closest(classPrefix('action-item', true, true));
        if (actionItem) {
          actionItemRefString = actionItem.getAttribute('data-tickaroo-action-item-ref');
          if (actionItemRefString) {
            actionItemRefString = actionItemRefString.replace(/\\u0022/g, '"').replace(/\\u0026/g, "&");
            actionItemRefObject = JSON.parse(actionItemRefString);

            switch (actionItemRefObject._type) {
              case "Tik::ApiModel::TickerRef":
                if (actionItemRefObject.ticker_id) {
                  scoreboardClicked(actionItemRefObject.ticker_id);
                }
                break;
              case "Tik::ApiModel::TournamentGamedayRef":
                tournament_id = actionItemRefObject.tournament_id;
                date = actionItemRefObject.date
                loadGamedayScore();
                break;
              default:
                console.error("Tickaroo EmbedJS: Unknown action type");
            }
          }
        }
      };

      var scoreboardClicked = function(id) {
        if (options.scoreboardClickCallback) {
          try {
            options.scoreboardClickCallback.apply(returnObject, [id]);
          } catch (e) {
            console.error('Tickaroo EmbedJS: Error in the scoreboardClickCallback custom function!');
            console.error(e);
          }
        }
      };

      utils.delegate($container, 'click', classPrefix('action-item', false, true), actionItemClickHandler);

      var updateWithResult = function(result) {
        if (result.content) {
          var content = utils.domify(result.content);
          if (options.itemDOMPostProcessor) {
            try {
              options.itemDOMPostProcessor.apply(returnObject, [content]);
            } catch (e) {
              console.error('Tickaroo EmbedJS: Error in the itemDOMPostProcessor custom function!');
              console.error(e);
            }
          }

          for (var i = $container.children.length - 1; i >= 0 ; i--) {
            $container.removeChild($container.children[i]);
          }

          $container.appendChild(content);
          callFeatures('updateHandledContent');
        }
      };

      var loadGamedayScore = function () {
        var request = utils.corsRequestFn();
        request.onSuccess = function (result) {
          updateWithResult(result);
        };
        var url = config.embedGamedayScore;

        url = utils.appendGetParam(url, "client_id=" + clientId);
        if(tournamentId) {
          url = utils.appendGetParam(url, "tournament_id=" + tournamentId);
        }
        if(date) {
          url = utils.appendGetParam(url, "date=" + date);
        }
        if(options.style) {
          url = utils.appendGetParam(url, "style=" + options.style);
        }

        request.get(url);
      };
      loadGamedayScore();

      // optional feature vars
      var refreshIntervalId;
      var refreshInterval = config.refreshInterval;
      if(options.refreshInterval) {
        refreshInterval = options.refreshInterval;
      }

      // Autoreload
      if (refreshInterval) {
        refreshIntervalId = setInterval(function() {
          loadGamedayScore();
        }, refreshInterval);
      }

      var returnObject = {
        uid: uid
      };

      var features = [];
      var callFeatures = function(method, arg) {
        for(var i = 0; i < features.length; ++i) {
          var feature = features[i];
          if(feature[method]) {
            feature[method](arg);
          }
        }
      };
      // Initialize Features
      timestampOptions = {}
      timestampOptions.dateFormat = 'short_date'
      features.push(new featureTimestamps($container, timestampOptions, classPrefix));
      features.push(new featureNavigationDate($container, timestampOptions, classPrefix));

      // Construct the public return object
      returnObject.refresh = function() {
        loadGamedayScore();
      };

      returnObject.destroy = function() {
        callFeatures('destroy');
        utils.undelegateAll($container);
        clearInterval(refreshIntervalId);
        $container.innerHTML = '';
      };

      return returnObject;
  };
});
