var utils = require('./utils');

var updateTimestampElement = function(element, options, classPrefix) {
  var tsString = element.getAttribute('data-timestamp');
  var ts = parseInt(tsString);
  var components = utils.niceDateTime(ts, options);

  if (components[0]) {
    element.innerHTML = '<span class="' + classPrefix('event-item-meta-timestamp-date') + '">' + components[0] + '</span> <span class="' + classPrefix('event-item-meta-timestamp-time') + '">' + components[1] + '</span>';
  } else {
    element.innerHTML = '<span class="' + classPrefix('event-item-meta-timestamp-time') + '">' + components[1] + '</span>';
  }


  var li = utils.findAncestor(element, 'li');
  if (li) {
    li.className = li.className.replace(/\btickaroo-event-item-timeframe-.*\b/g, '');
    if (li.className.indexOf(components[2]) == -1) {
      li.classList.add(classPrefix('event-item-timeframe-' + components[2]));
    }
  }
};

var updateAllTimestampElements = function($container, options, classPrefix) {
  var elements = $container.querySelectorAll('[data-timestamp]');
  for (var i = 0; i < elements.length; ++i) {
    updateTimestampElement(elements[i], options, classPrefix);
  }
};

var tsIntervalId;

module.exports = function($container, options, classPrefix) {
  this.$container = $container;
  this.options = options;
  this.classPrefix = classPrefix;

  this.tsIntervalId = setInterval(function() {
    updateAllTimestampElements($container, options, classPrefix);
  }, 60000);
};

module.exports.prototype.updateHandledContent = function() {
  updateAllTimestampElements(this.$container, this.options, this.classPrefix);
};

module.exports.prototype.destroy = function() {
  if(this.tsIntervalId) {
    clearInterval(this.tsIntervalId);
  }
};
