var utils = require('./utils');

var updateResponsiveCssClass = function($container, classPrefix) {
  var ticker = $container.querySelectorAll(classPrefix('ticker', true))[0];
  if (ticker) {
    var width = $container.offsetWidth;
    if (width > 520) {
      ticker.classList.add(classPrefix('ticker-wide'));
      ticker.classList.remove(classPrefix('ticker-narrow'));
    } else {
      ticker.classList.remove(classPrefix('ticker-wide'));
      ticker.classList.add(classPrefix('ticker-narrow'));
    }
    if ('ontouchstart' in window) {
      ticker.classList.add(classPrefix('ticker-touch'));
    } else {
      ticker.classList.remove(classPrefix('ticker-touch'));
    }
  }
};

module.exports = function($container, classPrefix) {
  this.$container = $container;
  this.classPrefix = classPrefix;

  utils.delegate(window, 'resize', null, function (e) {
    setTimeout(function() { updateResponsiveCssClass($container, classPrefix) } , 100);
  });
};

module.exports.prototype.updateHandledContent = function() {
  updateResponsiveCssClass(this.$container, this.classPrefix);
};

module.exports.prototype.destroy = function() {
};
