var config = require('./config');

var components = {
  comments: {
    loader: function () {
      var existingTag = document.getElementById("tickaroo-embedjs-comments");
      if (!existingTag) {
        var scriptTag = document.createElement("script");
        scriptTag.id = "tickaroo-embedjs-comments";
        scriptTag.src = config.tickarooBaseUrl + "/webng/comments/index.js";
        scriptTag.async = true;
        scriptTag.defer = true;
        document.head.appendChild(scriptTag);
      }
    }
  }
};


module.exports = {
  registerComponent: function(comp) {
    var name = comp.name;

    if(components[name]) {
      if(components[name].resolve) {
        components[name].resolve(comp);
      }
    }
  },
  getComponent: function(name) {
    if(components[name]) {
      if (!components[name].promise) {
        components[name].promise = new Promise((resolve, reject) => {
          components[name].resolve = resolve;
          components[name].reject = reject;
          components[name].loader(resolve, reject);
        });
      }
      return components[name].promise;
    } else {
      throw new Error('Unknown component ' + name);
    }

  }
};
