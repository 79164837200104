var config = require('../config');
var utils = require('./utils');

module.exports = function($container, urlPatternParser, options, classPrefix) {
  utils.delegate(
    $container,
    'click',
    classPrefix('event-item-content-sharing', false, true),
    function(e) {
      var target = e.target;
      if (typeof target == 'object') {
        var eventItem = target.closest(classPrefix('event-item', true, true));
        var eventId = eventItem.dataset.tickarooId;
        if (eventId) {
          var size = utils.getElSize(eventItem);
          var hrefClass = target.parentNode.className || '';
          var url = urlPatternParser(config.embedShareEventRedirectUrl).replace("EVENTID", eventId) + "&source_url=" + encodeURIComponent(options.referrer);
          if (hrefClass.indexOf('sharing-facebook') != -1) {
            utils.popupCenter(url.replace("TARGET", 'facebook'), '', size.w, 600);
          } else if (hrefClass.indexOf('sharing-twitter') != -1) {
            utils.popupCenter(url.replace('TARGET', 'twitter'), '', 600, 300);
          } else if (hrefClass.indexOf('sharing-email') != -1) {
            // utils.popupCenter(url.replace('TARGET', 'email'), 'mail!', 800, 600);
            var request = utils.corsRequestFn();
            request.onComplete = function (result) {};
            request.onFailure = function (result) {};

            request.onSuccess = function(result) {
              var url = result.url || '';
              window.location.href = 'mailto:?body=' + url; //subject=&
            };
            request.post(
              urlPatternParser(config.embedSharingPost),
              JSON.stringify({
                reftype: 'Event',
                refid: eventId,
                source_url: options.referrer
              })
            );
          }
        }
      }
    }
  );
  utils.delegate(
    $container,
    'click',
    classPrefix('event-item-content-sharing-open-button', false, true),
    function(e) {
      var target = e.target;
      if (typeof target == 'object') {
        var sharingHolderItem = target.closest(classPrefix('event-item-content-sharing-list', true, false));
        sharingHolderItem.classList.add(classPrefix('event-item-content-sharing-list--open'));
      }
    }
  );
  utils.delegate(
    $container,
    'click',
    classPrefix('event-item-content-sharing-close-button', false, true),
    function(e) {
      var target = e.target;
      if (typeof target == 'object') {
        var sharingHolderItem = target.closest(classPrefix('event-item-content-sharing-list', true, false));
        sharingHolderItem.classList.remove(classPrefix('event-item-content-sharing-list--open'));
      }
    }
  );
};

module.exports.prototype.destroy = function() {

};
