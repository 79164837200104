var utils = require('./utils');

var updateDateElement = function(element, options, classPrefix) {
  actionItemRefString = element.getAttribute('data-tickaroo-action-item-ref');
  if (actionItemRefString) {
    actionItemRefString = actionItemRefString.replace(/\\u0022/g, '"').replace(/\\u0026/g, "&");
    actionItemRefObject = JSON.parse(actionItemRefString);
    if(actionItemRefObject && actionItemRefObject.date) {
      var components = utils.niceDate(Date.parse(actionItemRefObject.date), options);

      if (components[0]) {
        element.innerHTML = '<span class="' + classPrefix('event-item-meta-timestamp-date') + '">' + components[0] + '</span>';
      }
    }
  }
};

var updateAllDateElements = function($container, options, classPrefix) {
  var elements = $container.querySelectorAll(classPrefix('navigation-item', true));
  for (var i = 0; i < elements.length; ++i) {
    updateDateElement(elements[i], options, classPrefix);
  }
};

var tsIntervalId;

module.exports = function($container, options, classPrefix) {
  this.$container = $container;
  this.options = options;
  this.classPrefix = classPrefix;

  this.tsIntervalId = setInterval(function() {
    updateAllDateElements($container, options, classPrefix);
  }, 60000);
};

module.exports.prototype.updateHandledContent = function() {
  updateAllDateElements(this.$container, this.options, this.classPrefix);
};

module.exports.prototype.destroy = function() {
  if(this.tsIntervalId) {
    clearInterval(this.tsIntervalId);
  }
};
