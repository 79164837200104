var utils = require('./utils');
var queryString = require('query-string');

function makeSeoLink(link, tickerId, rel) {
  var refJSON = link.getAttribute("data-tickaroo-action-item-ref")
  if(refJSON) {
    var ref = JSON.parse(refJSON.replace(/\\u0022/g, '"').replace(/\\u0026/g, "&"));

    var query = queryString.parseUrl(window.location.href).query || {};
    query.tickaroo_more = ref.more;

    var href = "?" + queryString.stringify(query);
    link.setAttribute('href', href);

    var linkHeaderId = "tickaroo-" + rel + "-" + tickerId;
    var headNextLink = document.getElementById(linkHeaderId);
    if (!headNextLink) {
      headNextLink = document.createElement("link");
      headNextLink.setAttribute("id", linkHeaderId);
      headNextLink.setAttribute("rel", rel);
      document.head.appendChild(headNextLink);
    }
    headNextLink.setAttribute("href", href);
  }
}

function makeSeoLinkById($container, itemId, tickerId, rel) {
  var moreLinks = $container.querySelectorAll("[data-tickaroo-action-item-id=" + itemId + "]");
  if(moreLinks.length === 1) {
    var moreLink = moreLinks[0];
    makeSeoLink(moreLink, tickerId, rel);
  }
}

module.exports = function(id, $container, options) {
  this.id = id;
  this.$container = $container;
};

module.exports.prototype.updateHandledContent = function() {
  makeSeoLinkById(this.$container, "events-more-item", this.id, "next");
};

module.exports.prototype.preInitialLoad = function(loadObj) {
  var url = queryString.parseUrl(window.location.href);
  if(url && url.query && url.query.tickaroo_more) {
    loadObj.url += "&deep_link=true&more=" + encodeURIComponent(url.query.tickaroo_more);
  }
};

module.exports.prototype.destroy = function() {

};
