define('embed/ticker', ['./utils', '../config', '@tickaroo/web-embed'],
  function(utils, config, WebEmbed) {
    var handleWebEmbeds = function(options) {
      require('../css/media-handler.scss');

      var mediaServerUrl = config.webEmbedEndpointUrl.replace("CLIENTID", options.clientId || config.clientId);

      var webEmbed = new WebEmbed({
        mediaServerUrl: mediaServerUrl,
        locale: utils.detectAndSetLocale(options.locale),
        corsRequestFn: utils.corsRequestFn
      });
      webEmbed.loadAll({
        container: options.container,
        selector: options.selector
      });
    };

    return {
      handleWebEmbeds: handleWebEmbeds
    };
  });
