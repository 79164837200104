define('embed/tik3_gameday_scores', ['./gameday_scores_setup'],
  function(embedScoresSetup) {

    var embedScores = function(options) {
      if (options.style != 'off') {
        require('../css/tik3_ticker.scss');
        if (!options.style) {
          options.style = "tik3";
        }
      }
      return embedScoresSetup(options, {
        prefix: 'tik3',
        prefixJS: 'tikjs'
      });
    };
    return {
      embedScores: embedScores
    };

});
