var config = require('./config');
var i18n = require('./i18n/i18n');
var utils = require('./embed/utils');
var ticker = require('./embed/tik3_ticker');
var iframe = require('./embed/iframe');
var web_embed = require('./embed/web_embed');
var video = require('./embed/video');
var gameday_scores = require('./embed/tik3_gameday_scores');
var components = require('./components');
var user = require('./user');

i18n.defaultLocale = 'en';
utils.detectAndSetLocale();

var embed = {
  q: function () {
    // try {
      if (typeof arguments[0] === "function") arguments[0]();
      else {
        var name = arguments[0];
        var args = Array.prototype.slice.call(arguments, 1);
        embed[name].apply(embed, args);
      }
    // } catch (e) {console.log(e)}
  },
  initButton: function() {}, // noop now
};
utils.extend(embed, ticker);
utils.extend(embed, iframe);
utils.extend(embed, web_embed);
utils.extend(embed, video);
utils.extend(embed, gameday_scores);
utils.extend(embed, components);
utils.extend(embed, user);

module.exports = embed;
