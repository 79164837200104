var utils = require('./utils');
var queryString = require('query-string');



module.exports = function($container, userDisplaySettings, options) {
	// load user settings
	var userSettings = userDisplaySettings.getUserSettings(options.id);
	if (userSettings.tag_filter) options.tagFilter = userSettings.tag_filter;
	
	var hashFilter = utils.getHashParam(options.referrer, 'tickaroo_tag_filter');
	if (hashFilter) {
		options.tagFilter = hashFilter;
	}
		
	var url = queryString.parseUrl(options.referrer);
  if(url && url.query && url.query.tickaroo_tag_filter) {
		options.tagFilter = url.query.tickaroo_tag_filter;
  }
};
