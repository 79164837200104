define("env", [], function() {
  return {
    tickarooBaseUrl: "https://staging.tickaroo.com",
    apiBaseUrl: "http://localhost:8080",
    // embedApiBaseUrl: "//staging-cdn.tickaroo.com",
    // embedApiBaseUrl: "//tickaroo.local:3006",
    embedApiBaseUrl: "//tickaroo.local:3007",
    analyticsApiBaseUrl: "//tickaroo.local:3006",
    refreshInterval: 20000,
    useV2: 1
  }
});
