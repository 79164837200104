var utils = require('./utils');

module.exports = function(options, config, loadTicker, urlPatternParser, errorWrapper) {
  this.options = options;
  this.config = config;
  this.loadTicker = loadTicker;
  this.urlPatternParser = urlPatternParser;
  // Autoreload
  if (options.refreshInterval && options.handleRefresh !== 'off') {
    this.refreshIntervalId = setInterval(errorWrapper(this.runRefresh.bind(this)), options.refreshInterval);
  }
};

module.exports.prototype.setVersion = function(params) {
  this.version = params.result.version;
};

module.exports.prototype.runRefresh = function() {
  // old style
  if (this.config.refreshLink) {
    this.loadTicker(this.config.refreshLink, 'auto', 'refresh');
  }
  // new style
  if(this.version) {
    var initialLoadUrl = this.urlPatternParser(this.options.urlPattern);
    var refreshLink = utils.addParameterToURL(initialLoadUrl, "refresh", this.version);

    this.loadTicker(refreshLink, 'auto', 'refresh');
  }
};


module.exports.prototype.destroy = function() {
  clearInterval(this.refreshIntervalId);
};

