var utils = require('./utils');


function handleContentWarning(aNode) {
  var contentWarning = aNode.getAttribute("data-tickaroo-media-content-warning");

  if(contentWarning !== null) {

    aNode.removeAttribute("data-tickaroo-media-content-warning");
    aNode.classList.remove("tik3-media-item--has-warning");
    aNode.querySelector('.tikjs-content-warning').remove();

    return true;
  } else {
    return false;
  }
}

module.exports = function($container, type, analytics, trackingCallback, urlPatternParser, uid, options, classPrefix, isTik3) {
  this.$container = $container;
  this.uid = uid;
  this.classPrefix = classPrefix;

  if(type === 'auto') {
    this.type = 'glightbox';
  } else {
    this.type = type;
  }

  // handleMedia
  var handlerFn;
  if(this.type === 'glightbox') {
    handlerFn = function(e) {
      e.preventDefault();
      var aNode = e.realTarget;
      var selectedMediaId = aNode.getAttribute('data-tickaroo-media-id');
      var player = aNode.getAttribute('data-player');

      if(handleContentWarning(aNode)) {
        return;
      } else if (player) {
        player = utils.appendGetParam(player, 'autoplay=true');
        var iframeHTML = '<iframe frameborder="0" scrolling="no" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" src="' + player + '"></frame>';
        var mediaItemVideoPlayerElem = document.createElement("div");
        mediaItemVideoPlayerElem.classList.add(classPrefix('media-item-video-player', false));
        mediaItemVideoPlayerElem.innerHTML = iframeHTML;
        aNode.appendChild(mediaItemVideoPlayerElem);
      } else {
        import('glightbox').then(function (GLightbox) {
          if (!this.glightbox) {
            this.glightbox = new GLightbox.default({
              selector: null,
              skin: 'tik',
              openEffect: 'tikzoom',
              closeEffect: 'tikzoom',
              svg: {
                close: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24"><path d="M18 6L6 18M6 6l12 12"/></svg>',
                next: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24"><path d="M9 18l6-6-6-6"/></svg>',
                prev: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24"><path d="M15 18l-6-6 6-6"/></svg>'
              },
              cssEfects: {
                tikzoom: {in: 'tikzoomIn', out: 'tikzoomOut'}
              }
            });
          }
          var selectedIndex = this.updateGlightbox(selectedMediaId);
          this.glightbox.openAt(selectedIndex);
        }.bind(this));
      }
    }.bind(this);
    utils.delegate($container, 'click', 'tikjs-media-item', handlerFn);
  } else if (this.type === 'rooverlay') {
    handlerFn = function(e) {
      var aNode = e.realTarget;

      if(handleContentWarning(aNode)) {
        return;
      }

      var rooverlay = new Rooverlay(utils.extend({
        paginationDescending: true,
        pagination: true,
        closeOnOverlayClick: true,
        onBeforeSlideRender: function() {
          if(rooverlay.slide &&
            rooverlay.slide.media &&
            rooverlay.slide.media.trackable &&
            rooverlay.slide.media.trackable.event) {

            rooverlay.slide.media.trackable.event.loadreason = 'user';
            if (analytics) {
              analytics.track(rooverlay.slide.media.trackable.event);
            }
          }

          trackingCallback(options.id, 'user', 'slideshow', 1);
        }
      }, options.rooverlay));
      rooverlay.showLoading();

      var request = utils.corsRequestFn();
      request.onSuccess = function(result) {
        var slidesArray = [];
        var selectedMediaId = aNode.getAttribute('data-tickaroo-media-id');
        var selectedMediaIndex = 0;

        if (result.items && result.items.length) {
          for (var i = 0; i < result.items.length; ++i) {
            var media = result.items[i];
            if (selectedMediaId === media._id) {
              selectedMediaIndex = i;
            }
            var slideOptions, imageSrc;
            if (media.is_playable) {
              slideOptions = {
                aspectRatio: true,
                src: utils.appendGetParam(media.player_url, 'autoplay=true'),
                type: 'iframe-video',
                height: media.height,
                width: media.width
              };
            } else {
              imageSrc = utils.appendGetParam(utils.mediaUrlAutoFormat(media.url, "xxhdpi"), 'client_id=' + options.clientId);
              slideOptions = {
                src: imageSrc,
                type: 'image'
              };
            }
            slideOptions.media = media;
            var text = [];
            function addText(type, txt) {
              text.push('<span class="' + classPrefix('tickaroo-slideshow-media-' + type) + '">' + utils.escapeHTML(txt) + "</span>");
            }

            if (media.title) {
              addText('title', media.title);
            } else if (media.event && media.event.title) {
              if (media.event.title.length > 400) {
                addText('title', media.event.title.substring(0, 400) + '…');
              } else {
                addText('title', media.event.title);
              }
            }
            if (media.credit) {
              addText('credit', media.credit);
            }
            slideOptions.description = text.join('<br>')
            slidesArray.push(slideOptions);
          }

          rooverlay.updateSlidesAndRerenderWithIndex(slidesArray, selectedMediaIndex);
        } else {
          rooverlay.destroy();
        }
      };

      request.onFailure = function() {
        rooverlay.destroy();
      };

      if (aNode.getAttribute('data-tickaroo-slideshow-url')) {
        request.get(urlPatternParser(aNode.getAttribute('data-tickaroo-slideshow-url')));
      } else {
        rooverlay.destroy();
      }
    };
    // todo: ugly but safe hack, should fix on pro later
    utils.delegate($container, 'click', classPrefix('media-item', false, true), handlerFn);
    utils.delegate($container, 'click', classPrefix('imagegrid-media-item', false, true), handlerFn);
  } else if (this.type === 'inline') {
    handlerFn = function(e) {
      var aNode = e.realTarget;
      var handle = aNode.getAttribute('data-tickaroo-handle');
      if (handle !== 'false') {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
        var player = aNode.getAttribute('data-player');
        if (player) {
          player = utils.appendGetParam(player, 'autoplay=true');
          if (isTik3) {
            var iframeHTML = '<iframe frameborder="0" scrolling="no" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" src="' + player + '"></frame>';
            var mediaItemVideoPlayerElem = document.createElement("div");
            mediaItemVideoPlayerElem.classList.add(classPrefix('media-item-video-player', false));
            mediaItemVideoPlayerElem.innerHTML = iframeHTML;
            aNode.appendChild(mediaItemVideoPlayerElem);
          }
          else {
              var framecode = '<div class="' + classPrefix('media-item', false) + ' ' + classPrefix('media-item-video-player', false) + '"><iframe frameborder="0" scrolling="no" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" src="' + player + '"></frame></div>';
              aNode.parentNode.innerHTML = framecode;
          }
        }
      }
    };
    utils.delegate($container, 'click', classPrefix('media-item', false, true), handlerFn);
    utils.delegate($container, 'click', classPrefix('imagegrid-media-item', false, true), handlerFn);
  }
};

module.exports.prototype.updateGlightbox = function(selected) {
  var mediaItems = [];
  this.$container.querySelectorAll('[data-tickaroo-media-id]').forEach(function(node) {
    if(!node.getAttribute('data-player')) {
      mediaItems.push(node);
    }
  });

  var elements = [];

  var selectedIndex = 0;

  mediaItems.forEach(function(node, index) {
    if(node.getAttribute("data-tickaroo-media-id") === selected) {
      selectedIndex = elements.length;
    } else if(node.hasAttribute('data-tickaroo-media-content-warning')) {
      return; // skip content warning images
    }

    var player = node.getAttribute('data-player');
    var title = node.getAttribute('title');
    var credit = node.getAttribute('data-tickaroo-media-credit');
    {/* Add punctuation if not in title and credit available */}
    if (title && !title.match(/[.;!?,:]$/) && credit) {
      title = title + '<span class="gslide-title-auto-punctuation">.</span>'
    }

    var titleCredit = {
      ...(title && {title: title}),
      ...(credit &&{description: credit})
    }

    if(player) {
      var mediaOriginalWidth = node.getAttribute('data-tickaroo-media-original-width');
      var mediaOriginalHeight = node.getAttribute('data-tickaroo-media-original-height');
      var mediaRatio = Math.round(mediaOriginalHeight/mediaOriginalWidth * 1000) / 1000;

      // var sources = node.getAttribute('data-tickaroo-sources').split(' ');
      //
      // elements.push({
      //   type: 'video',
      //   source: 'local',
      //   href: sources[sources.length -1],
      //   ...titleCredit
      // })

      var iframeHTML = '<div class="gslide-jwp-wrapper"><div class="gslide-jwp-container"><div class="gslide-jwp-container-ratio" style="padding-bottom:'+ mediaRatio*100 +'%;"></div><iframe class="gslide-jwp-iframe" frameborder="0" scrolling="no" allow="fullscreen" src="' + player + '"></iframe></div><div class="gslide-jwp-description"><div class="gdesc-jwp-inner"><h4 class="gslide-jwp-title">'+ (title ? title : '') +'</h4> <span class="gslide-jwp-desc">' + (credit ? credit : '') + '</span></div></div></div>';
      elements.push({
        content: iframeHTML,
        width: mediaOriginalWidth,
        height: 'auto'
      });
    } else {
      elements.push({
        type: 'image',
        href: node.getAttribute('href'),
        ...titleCredit
      });
    }
  }.bind(this));

  this.glightbox.setElements(elements);

  return selectedIndex;
};

module.exports.prototype.updateHandledContent = function() {
  if (this.type === 'inline') {
    //add css
    var eventList = this.$container.querySelectorAll(this.classPrefix('event-list', true, true))[0];
    if (eventList) {
      eventList.classList.add(this.classPrefix('ticker--handle-media-inline'));
    }
  }
};

module.exports.prototype.destroy = function() {
  if(this.glightbox) {
    this.glightbox.destroy();
  }
};
