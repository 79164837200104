var utils = require('./utils');

module.exports = function($container, options, navigateToEventId) {
  var callLocation = location.hash;
  if(options.referrer) {
    callLocation = options.referrer;
  }

  this.$container = $container;
  this.eventId = options.eventId || utils.getHashParam(callLocation, 'tickaroo_event_id');
  this.navigateToEventId = navigateToEventId;
};

module.exports.prototype.updateHandledContent = function() {

};


module.exports.prototype.preInitialLoad = function(loadObj) {
  if(this.eventId && this.eventId !== 'undefined') {
    loadObj.url += "&deep_link=true&event_local_id=" + encodeURIComponent(this.eventId);
  }
};

module.exports.prototype.postInitialLoad = function(loadObj) {
  if (this.eventId && this.eventId !== 'undefined') {
    this.navigateToEventId(this.eventId);
  }
};

module.exports.prototype.destroy = function() {

};
