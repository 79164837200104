define('i18n/i18n', ["./i18n-js", "./embed.de.json", "./embed.json", "./embed.en-GB.json"], function(i18n, de, en, en_gb) {

  function isMergeableObject(val) {
      var nonNullObject = val && typeof val === 'object'

      return nonNullObject
          && Object.prototype.toString.call(val) !== '[object RegExp]'
          && Object.prototype.toString.call(val) !== '[object Date]'
  }

  function emptyTarget(val) {
      return Array.isArray(val) ? [] : {}
  }

  function cloneIfNecessary(value, optionsArgument) {
      var clone = optionsArgument && optionsArgument.clone === true
      return (clone && isMergeableObject(value)) ? deepmerge(emptyTarget(value), value, optionsArgument) : value
  }

  function defaultArrayMerge(target, source, optionsArgument) {
      var destination = target.slice()
      source.forEach(function(e, i) {
          if (typeof destination[i] === 'undefined') {
              destination[i] = cloneIfNecessary(e, optionsArgument)
          } else if (isMergeableObject(e)) {
              destination[i] = deepmerge(target[i], e, optionsArgument)
          } else if (target.indexOf(e) === -1) {
              destination.push(cloneIfNecessary(e, optionsArgument))
          }
      })
      return destination
  }

  function mergeObject(target, source, optionsArgument) {
      var destination = {}
      if (isMergeableObject(target)) {
          Object.keys(target).forEach(function (key) {
              destination[key] = cloneIfNecessary(target[key], optionsArgument)
          })
      }
      Object.keys(source).forEach(function (key) {
          if (!isMergeableObject(source[key]) || !target[key]) {
              destination[key] = cloneIfNecessary(source[key], optionsArgument)
          } else {
              destination[key] = deepmerge(target[key], source[key], optionsArgument)
          }
      })
      return destination
  }

  function deepmerge(target, source, optionsArgument) {
      var array = Array.isArray(source);
      var options = optionsArgument || { arrayMerge: defaultArrayMerge }
      var arrayMerge = options.arrayMerge || defaultArrayMerge

      if (array) {
          return Array.isArray(target) ? arrayMerge(target, source, optionsArgument) : cloneIfNecessary(source, optionsArgument)
      } else {
          return mergeObject(target, source, optionsArgument)
      }
  }


  i18n.translations || (i18n.translations = {});

  // I18n plugin uses global namespace I18n if it exists.
  // This affects existing translations, so we merge the translations.
  // Deep merge would be even better.

  i18n.translations["en"] || (i18n.translations["en"] = {})
  i18n.translations["en"] = deepmerge(i18n.translations["en"], en);

  i18n.translations["en-gb"] || (i18n.translations["en-gb"] = {})
  i18n.translations["en-gb"] = deepmerge(i18n.translations["en-gb"], en);
  i18n.translations["en-gb"] = deepmerge(i18n.translations["en-gb"], en_gb);


  i18n.translations["de"] || (i18n.translations["de"] = {})
  i18n.translations["de"] = deepmerge(i18n.translations["de"], de);

  return i18n;
});
