define('embed/ticker', ['./utils', '../config', './ticker_setup'],
  function(utils, config,  embedTickerSetup) {

    utils.extend(this, utils);

    utils.installClosestPolyfill();

    var selectors = {
      prefix: "tik3",
      prefixJS: "tikjs",
      scoreboardContainer: ".tikjs-scoreboard-container",
      loadMoreButton: ".tikjs-load-more",
      eventReporter: ".tikjs-event-item-meta-reporter",
      eventList: ".tikjs-event-list",
      actionItem: ".tikjs-action-item",
      refItem: ".tikjs-ref-item"
    };

    var optionsReference;
    var embedLiveblog = function (options) {
      optionsReference = options;
      if (!optionsReference) {
        utils.raise("Missing Options");
      }
      if (!options.id) {
        utils.raise("Missing Ticker ID");
      }

      options.urlPattern = config.embedTickerUrlPattern;
      options.loadMoreUrlPattern = config.embedTickerUrlPattern;

      require('../css/tik3_ticker.scss');
      options.style = "tik3";
      options.version = "tik3";

      returnObject = embedTickerSetup(options, {
        showEventMeta: 'reporter',
        coalesceRepeatedMeta: true,
        showLineup: true
      }, selectors, true);

      returnObject.overrideOptions = function (options) {
        options = options || {};
        options = utils.extend(optionsReference, options);

        returnObject.destroy();
        returnObject = embedLiveblog(options);
        return returnObject;
      };

      return returnObject;
    };

    return {
      embedLiveblog: embedLiveblog
    };
  });
