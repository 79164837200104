var components = require('../components');
var config = require('../config');
var user = require('../user');
var utils = require('./utils');

module.exports = function ($container, options) {
  this.$container = $container;
  this.clientId = options.clientId;
  this.locale = options.locale;
};

module.exports.prototype.updateLocale = function(locale) {
  this.locale = locale;
};

module.exports.prototype.getCommentsComponentPromise = function() {
  if(!this.commentsComponentPromise) {
    this.commentsComponentPromise = components.getComponent("comments");
  }

  return this.commentsComponentPromise;
};

module.exports.prototype.initializeComments = function(element) {
  var commentsEls = element.getElementsByClassName("tik3-comments");
  if(commentsEls && commentsEls.length > 0) {
    var commentsEl = commentsEls[0];
    var version = commentsEl.getAttribute('data-tickaroo-version');
    if(commentsEl && commentsEl.getAttribute("data-tickaroo-comment-initialized") !== version ) {
      commentsEl.setAttribute("data-tickaroo-comment-initialized", version);
      this.getCommentsComponentPromise().then(function (comp) {
        comp.embedCommentsChannelPreview({
          ownerId: commentsEl.getAttribute("data-tickaroo-owner-id"),
          channelId: commentsEl.getAttribute("data-tickaroo-channel-id"),
          version: version,
          externalUserProvider: user,
          container: commentsEl,
          host: config.tickarooBaseUrl,
          clientId: this.clientId,
          locale: utils.detectAndSetLocale(this.locale)
        });
      }.bind(this));
    }
  }
};

module.exports.prototype.destroyComments = function(element) {
  var commentsEls = element.getElementsByClassName("tik3-comments");
  if(commentsEls.length > 0) {
    var commentsEl = commentsEls[0];
    if(commentsEl && commentsEl.getAttribute("data-tickaroo-comment-initialized")) {
      this.getCommentsComponentPromise().then(function (comp) {
        comp.embedCommentsDestroy(commentsEl);
      }.bind(this));
    }
  }
};

module.exports.prototype.afterEventNodeMount = function(element) {
  this.initializeComments(element);
};

module.exports.prototype.beforeEventNodeUnmount = function(element) {
  this.destroyComments(element);
};
