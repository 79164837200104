define('embed/utils', ['../config.js', '../i18n/i18n'], function (settings, i18n) {
  var hasOwnProperty = Object.prototype.hasOwnProperty;

  var utils = {
    appendGetParam: function (url, params) {
      var bind = url.indexOf('?') === -1 ? '?' : '&';
      return url + bind + params;
    },
    getHashParam: function (hashString, paramKey) {
      hashString = hashString || '';
      paramKey = paramKey || '';
      hashString = hashString.split(paramKey + '=')[1] || '';
      if (hashString.split('&')[0] === '') {
        return undefined
      } else {
        return hashString.split('&')[0]
      }
    },
    escapeHTML: function (text) {
      var output = '';
      for (var i = 0; i < text.length; i++) {
        switch (text[i]) {
        case '<':
          output += '&lt;';
          break;
        case '>':
          output += '&gt;';
          break;
        case '&':
          output += '&amp;';
          break;
        case '"':
          output += '&quot;';
          break;
        case "'":
          output += '&apos;';
          break;
        default:
          output += text[i];
        }
      }
      return output;
    },
    raise: function (error) {
      if (console) console.error.apply(console, arguments);
      throw error;
    },
    $: function (el_or_id) {
      if (typeof el_or_id == 'string') {
        return document.body.querySelector(el_or_id);
      } else {
        return el_or_id;
      }
    },
    domify: function (html) {
      var div = document.createElement('div');
      div.innerHTML = html;
      var ret = div.firstChild;
      while (ret) {
        if (ret.nodeType == 1) {
          return ret;
        } else {
          ret = ret.nextSibling;
        }
      }
      return null;
    },
    extend: function (obj) {
      var source, prop;
      for (var i = 1, length = arguments.length; i < length; i++) {
        source = arguments[i];
        for (prop in source) {
          if (hasOwnProperty.call(source, prop)) {
            obj[prop] = source[prop];
          }
        }
      }
      return obj;
    },
    fireClick: function (node) {
      if (document.createEvent) {
        var evt = document.createEvent('MouseEvents');
        evt.initEvent('click', true, true);
        node.dispatchEvent(evt);
      } else if (document.createEventObject) {
        node.fireEvent('onclick');
      } else if (typeof node.onclick == 'function') {
        node.onclick();
      }
    },
    makeDelegateFunction: function (eventName, delegations) {
      return function (e) {
        var delegates = delegations[eventName].delegates;
        var realTarget = e.target || e.srcElement;
        var realTargetFound = false;

        while (realTarget && !realTargetFound) {
          for (var i = 0; i < delegates.length; ++i) {
            var delegate = delegates[i];
            if (realTarget && (!delegate.klass || realTarget.className &&
                (' ' + realTarget.className + ' ').indexOf(' ' +
                  delegate.klass + ' ') > -1)) {

              e.realTarget = realTarget;
              delegate.handler(e);
              if (e.preventDefault) {
                e.preventDefault();
              }
              if (e.stopPropagation) {
                e.stopPropagation();
              }
              e.returnValue = false;
              e.cancelBubble = true;

              realTargetFound = true;
              break;
            }
          }

          if (!realTargetFound) {
            if (realTarget == e.currentTarget) {
              realTargetFound = true;
              break;
            } else {
              realTarget = realTarget.parentNode;
            }
          }
        }
      }
    },
    addEventListener: function (el, event, f) {
      if (el.addEventListener) {
        el.addEventListener(event, f);
      } else {
        el.attachEvent('on' + event, f);
      }
    },
    removeEventListener: function (el, event, f) {
      if (el.removeEventListener) {
        el.removeEventListener(event, f);
      } else {
        el.detachEvent('on' + event, f);
      }
    },
    delegate: function (el, event, klass, func) {
      el._tikDelegations = el._tikDelegations || {};
      if (!el._tikDelegations[event]) {
        var f = this.makeDelegateFunction(event, el._tikDelegations);

        el._tikDelegations[event] = {
          event: event,
          handler: f,
          delegates: []
        }
        this.addEventListener(el, event, f);
      }
      el._tikDelegations[event].delegates.push({
        klass: klass,
        handler: func
      });

      var el = this.$(el);
    },
    undelegateAll: function (el) {
      if (el._tikDelegations) {
        for (var i in el._tikDelegations) {
          var delegation = el._tikDelegations[i];
          this.removeEventListener(el, delegation.event, delegation.handler);
        }
        delete el._tikDelegations;
      }
    },
    scrollToEl: function (el, options) {
      options = options || {};
      if (el) {
        var eventClientRect = el.getBoundingClientRect();
        window.scrollTo({
          behavior: "smooth",
          top: window.pageYOffset + eventClientRect.top - (options.topOffset || 0)
        });
      }
    },
    corsRequestFn: function (opts) {
      opts = opts || {};
      var request = {};
      var createXMLHTTPObject = function () {
        var XMLHttpFactories = [
          function () {
            return new XDomainRequest()
          },
          function () {
            return new XMLHttpRequest()
          },
        ];

        var xmlhttp = false;
        for (var i = 0; i < XMLHttpFactories.length; i++) {
          try {
            xmlhttp = XMLHttpFactories[i]();
          } catch (e) {
            continue;
          }
          break;
        }
        return xmlhttp;
      }

      request.onSuccess = opts.onSuccess;
      request.onFailure = opts.onFailure;
      request.onComplete = opts.onComplete;
      request.xhr = createXMLHTTPObject();

      request.req = function (method, url, data) {
        this.xhr.onerror = function () {
          if (this.onFailure) {
            this.onFailure(request);
          }
          if (this.onComplete) {
            this.onComplete(request);
          }
        }.bind(this);
        this.xhr.ontimeout = function () {
          if (this.onFailure) {
            this.onFailure(request);
          }
        }.bind(this);
        this.xhr.onprogress = function () {};
        this.xhr.onload = function () {
          if (typeof this.xhr.status === 'undefined' || this.xhr.status == 200 || this.xhr.status == 304) {
            if (this.onSuccess) {
              var resp = JSON.parse(this.xhr.responseText);
              this.onSuccess(resp);
            }
          } else {
            if (this.onFailure) {
              this.onFailure(request);
            }
          }
          if (this.onComplete) {
            this.onComplete(request);
          }
        }.bind(this);
        this.xhr.open(method, url.replace('https:', '').replace('http:', ''));
        this.xhr.timeout = 10000;
        if (data) {
          if (this.xhr.setRequestHeader) {
            this.xhr.setRequestHeader("Content-Type", "application/json");
          }
          if (typeof data == "string") {
            this.xhr.send(data);
          } else {
            this.xhr.send(JSON.stringify(data));
          }
        } else {
          this.xhr.send();
        }
        return this.xhr;
      };

      request.post = function (url, data) {
        return this.req('post', url, data);
      };

      request.get = function (url, data) {
        return this.req('get', url, data);
      };

      return request;
    },
    uniqueIdCounter: 0,
    generateUID: function () {
      this.uniqueIdCounter++;
      return 'tik' + ('00000000' + this.uniqueIdCounter).slice(-8);
    },
    appendStylesheet: function (id, file_path) {
      var head, link, cssId = id; // you could encode the css path itself to generate id..
      if (!document.getElementById(cssId)) {
        head = document.getElementsByTagName('head')[0];
        link = document.createElement('link');
        link.id = cssId;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = settings.widgetUrl + '/' + file_path;
        link.media = 'screen';
        head.appendChild(link);
      }
    },
    findAncestor: function (el, elName) {
      var parentEl = el.parentElement;
      while (parentEl && !parentEl.classList.contains('tickaroo-ticker')) {
        if (parentEl.tagName.toLowerCase() == elName.toLowerCase()) {
          return parentEl;
        } else {
          parentEl = parentEl.parentElement;
        }
      }
      return undefined;
    },
    installClosestPolyfill: function () {
      // element-closest | CC0-1.0 | github.com/jonathantneal/closest
      if (typeof Element.prototype.matches !== 'function') {
        Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.webkitMatchesSelector || function matches(selector) {
          var element = this;
          var elements = (element.document || element.ownerDocument).querySelectorAll(selector);
          var index = 0;
          while (elements[index] && elements[index] !== element) {
            ++index;
          }
          return Boolean(elements[index]);
        };
      }
      if (typeof Element.prototype.closest !== 'function') {
        Element.prototype.closest = function closest(selector) {
          var element = this;
          while (element && element.nodeType === 1) {
            if (element.matches(selector)) {
              return element;
            }
            element = element.parentNode;
          }
          return null;
        };
      }
    },
    emptyElement: function (el) {
      while (el.firstChild) {
        el.removeChild(el.firstChild);
      }
    },
    detectAndSetLocale: function (forceLocale) {
      var detectedLocale;
      forceLocale = forceLocale || '';
      forceLocale = forceLocale.toLowerCase();
      if (forceLocale == 'en' || forceLocale == 'de' || forceLocale == 'en-gb') {
        detectedLocale = forceLocale;
      } else {
        var locale = navigator.language || navigator.userLanguage;
        locale = locale.toLowerCase();
        if (locale == 'de' || locale == 'de-de' || locale == 'de-at' || locale == 'de-li' || locale == 'de-ch' || locale == 'de-lu') {
          locale = 'de'
        } else if(locale == 'en-gb' || locale == 'en-ie' || locale == 'en-au' || locale == 'en-nz') {
          locale = 'en-gb'
        } else {
          locale = i18n.defaultLocale;
        }
        detectedLocale = locale;
      }

      i18n.locale = detectedLocale;
      i18n.fallbacks = ['en'];
      return detectedLocale;
    },
    msPerDay: 86400 * 1000,
    lstrftime: function (format, date) {
      var fmt = i18n.t('embed.date.format.' + format);
      // var locale = i18n.t('embed.date.strftime');

      return i18n.strftime(date, fmt);
    },
    beginningOfDay: function (ts) {
      var start = new Date(ts);
      return start.setHours(0,0,0,0);
    },
    niceDate: function (ts, options) {
      var date = new Date(ts);
      var today = this.beginningOfDay(new Date());
      var theDay = this.beginningOfDay(ts);
      // round because of clock change (first day of summer time - last day of winter time => 0.95)
      var dayDiff = Math.round((today - theDay) / this.msPerDay);


      if (dayDiff == 0) {
        return [i18n.t('embed.date.today'), 'today'];
      } else if (dayDiff == 1) {
        return [i18n.t('embed.date.yesterday'), 'yesterday'];
      } else if (dayDiff == -1) {
        return [i18n.t('embed.date.tomorrow'), 'tomorrow'];
      } else if (dayDiff < 7 && dayDiff > 0) {
        var dayOfWeek = date.getDay();
        return [i18n.t('date.day_names')[dayOfWeek], 'days-ago'];
      } else if (dayDiff > -7 && dayDiff < 0) {
        var dayOfWeek = date.getDay();
        return [i18n.t('date.day_names')[dayOfWeek], 'days-in-the-future'];
      } else if (dayDiff <= -7) {
        var dateFormat = 'default_date';
        if (options && options.dateFormat) {
          dateFormat = options.dateFormat;
        }
        return [this.lstrftime(dateFormat, date), 'long-in-the-future'];
      }else {
        var dateFormat = 'default_date';
        if (options && options.dateFormat) {
          dateFormat = options.dateFormat;
        }
        return [this.lstrftime(dateFormat, date), 'long-ago'];
      }
    },
    niceDateTime: function (ts, options) {
      var date = new Date(ts);
      var now = new Date();
      var dist = now - date;

      var f = settings.dateFormat;
      if (dist < -f.nowThreshold) { // future
        var niceDate = this.niceDate(ts, options);
        return [niceDate[0], this.lstrftime('default_time', date),
          niceDate[1]
        ];
      } else if (dist < f.nowThreshold) {
        return ['', i18n.t('embed.date.just_now'), 'just-now'];
      } else if (dist < f.relativeThreshold) {
        var count = Math.round(dist / 60000);
        return ['', i18n.t('embed.date.minutes_ago', {
          count: count
        }), 'minutes-ago'];
      } else {
        var niceDate = this.niceDate(ts, options);
        return [niceDate[0], this.lstrftime('default_time', date),
          niceDate[1]
        ];
      }
    },
    findOrCreateContainer: function (container) {
      var $container;
      if (!container) {
        var uid = this.generateUID();
        document.write('<div id="' + uid + '"></div>');
        $container = this.$(uid);
      } else {
        $container = this.$(container);
      }
      if (!$container) {
        utils.raise("Missing container");
      }
      return $container;
    },
    popupCenter: function (url, title, w, h) {
      var dualScreenLeft, dualScreenTop, height, left, newWindow, top, width;
      dualScreenLeft = (window.screenLeft !== void 0 ? window.screenLeft : window.screen.left);
      dualScreenTop = (window.screenTop !== void 0 ? window.screenTop : window.screen.top);
      width = (window.innerWidth ? window.innerWidth : (document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width));
      height = (window.innerHeight ? window.innerHeight : (document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height));
      left = ((width / 2) - (w / 2)) + dualScreenLeft;
      top = ((height / 3) - (h / 3)) + dualScreenTop;
      newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
      if (newWindow && newWindow.focus && window.focus) {
        newWindow.focus();
      }
    },
    getElSize: function (el, defaultWidth, defaultHeight) {
      var w = defaultWidth || 500;
      var h = defaultHeight || 500;
      var size = {};
      var bcr = el.getBoundingClientRect();
      size.w = bcr.width || w;
      size.h = bcr.height || h;
      return size;
    },
    makeQueryString: function(ref) {
      var resp = [];
      var keys = Object.keys(ref);

      for(var i = 0; i < keys.length; ++i) {
        var key = keys[i];
        var value = ref[key];
        resp.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
      }

      return resp.join('&');
    },
    mediaUrlAutoFormat: function(url, format) {
      if(!url){
        return url;
      }
      if(this.isMediaproxyUrl(url)){
        return url.replace(/\/auto([?\/])/, "/" + format + "$1");
      }
      return url;
    },
    isMediaproxyUrl: function(url) {
      return (url.includes('tickaroo.com/v3/image/') || url.includes('tickaroo.com/api/mediaproxy/v1/external-image'))
    },
    addParameterToURL: function (url, key, value) {
      url += (url.indexOf('?') > -1 ? '&' : '?') + key + "=" + value;
      return url;
    },
    // Slightly modified version of https://github.com/ded/domready
    domready: (function (ready) {

      var fns = [],
        fn, f = false,
        doc = document,
        testEl = doc.documentElement,
        hack = testEl.doScroll,
        domContentLoaded = 'DOMContentLoaded',
        addEventListener = 'addEventListener',
        onreadystatechange = 'onreadystatechange',
        readyState = 'readyState',
        loadedRgx = hack ? /^loaded|^c/ : /^loaded|c/,
        loaded = loadedRgx.test(doc[readyState])

      function flush(f) {
        loaded = 1
        while (f = fns.shift()) f()
      }

      doc[addEventListener] && doc[addEventListener](domContentLoaded,
        fn = function () {
          doc.removeEventListener(domContentLoaded, fn, f)
          flush()
        }, f)


      hack && doc.attachEvent(onreadystatechange, fn = function () {
        if (/^c/.test(doc[readyState])) {
          doc.detachEvent(onreadystatechange, fn)
          flush()
        }
      })

      return (ready = hack ?
        function (fn) {
          self != top ?
            loaded ? fn() : fns.push(fn) :
            function () {
              try {
                testEl.doScroll('left')
              } catch (e) {
                return setTimeout(function () {
                  ready(fn)
                }, 50)
              }
              fn()
            }()
        } :
        function (fn) {
          loaded ? fn() : fns.push(fn)
        })
    })()
  };

  return utils;
});
